<template>
  <div class="modal-card">

    <header class="modal-card-head">
      <p class="modal-card-title m-0">Modifier la photo</p>
      <button type="button"
              class="delete"
              @click="$emit('close')"/>
    </header>

    <section class="modal-card-body">

      <cropper ref="cropper"
              :src="src"
              :stencil-props="{
                handlers: {},
                movable: false,
                scalable: false,
                aspectRatio: stencilAspectRatio,
              }"
              :stencil-size="{
                width: stencilSizeWidth,
                height: stencilSizeHeight,
              }"
              :transitions="true"
              :debounce="false"
              image-restriction="stencil"
              :stencil-component="stencilType"
              class="cropper"
              @change="change" />

      <div class="action_btn_container
                  is-flex
                  is-flex-direction-column
                  p-2">

        <b-button @click="rotate(90)"
                  type="is-light"
                  icon-right="rotate-right"
                  class="m-1" />

        <b-button @click="zoom(0.5)"
                  type="is-light"
                  icon-right="magnify-minus"
                  class="m-1" />

        <b-button @click="zoom(2)"
                  type="is-light"
                  icon-right="magnify-plus"
                  class="m-1" />

        <b-button @click="flip(true, false)"
                  type="is-light"
                  icon-right="axis-z-rotate-counterclockwise"
                  class="m-1" />

        <b-button @click="flip(false, true)"
                  type="is-light"
                  icon-right="horizontal-rotate-counterclockwise"
                  class="m-1" />

      </div>

    </section>

    <footer class="modal-card-foot is-flex is-justify-content-flex-end">
      <b-button label="Enregistrer Image"
                type="is-primary"
                @click="saveImage"
                icon-left="content-save"
                expanded />
    </footer>

  </div>
</template>

<script>
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';

export default {
  components: {
    Cropper,
  },
  props: {
    src: {
      type: String,
      default: null,
    },
    stencilType: {
      type: String,
      default: 'rectangle-stencil',
    },
    stencilAspectRatio: {
      type: Number,
      default: 1,
    },
    stencilSizeWidth: {
      type: Number,
      default: 300,
    },
    stencilSizeHeight: {
      type: Number,
      default: 300,
    },
    active: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      image: null,
      size: {
        width: null,
        height: null,
      },
      result: {
        coordinates: null,
        image: null,
      },
    };
  },
  created() {
    setTimeout(() => this.zoom(0.1), 500);
  },
  methods: {
    change({ coordinates, image }) {
      this.result = {
        coordinates,
        image,
      };
    },
    zoom(factor) {
      this.$refs.cropper.zoom(factor);
    },
    move() {
      this.$refs.cropper.move(100, 100);
    },
    flip(x, y) {
      if (this.$refs.cropper.customImageTransforms.rotate % 180 !== 0) {
        this.$refs.cropper.flip(!x, !y);
      } else {
        this.$refs.cropper.flip(x, y);
      }
    },
    rotate(angle) {
      this.$refs.cropper.rotate(angle);
    },
    saveImage() {
      const { canvas } = this.$refs.cropper.getResult();

      this.$emit('uploadLogo', canvas);
    },
    cancel() {
      this.$emit('cancel');
    },
    error() {
      // eslint-disable-next-line
      console.log('An error occured during image loading');
      this.loading = false;
    },
    ready() {
      // eslint-disable-next-line
      console.log('Image is successfully loaded');
      this.loading = false;
    },
  },
  computed: {
    isActive: {
      get() { return this.active; },
      set() { this.$emit('close'); },
    },
  },
};
</script>

<style scoped lang="scss">
  .modal-card-body {
    position: relative;
    padding: 0;

    /*
      Maybe you need to set the limits for the cropper sizes or its container sizes
      otherwise a cropping image will try to fill all available space
    */
    .cropper {
      position: relative;
      height: 400px;
      cursor: move;
    }

    .action_btn_container {
      position: absolute;
      bottom: 0;
      right: 0;
    }

  }
</style>